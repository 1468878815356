import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const Header: React.FC = () => {
  const { toggleLanguage, language } = useLanguage();

  return (
    <header className="sticky-header bg_flare">
      <div className="container">
        <div className="header-content">
          <div className="language-switcher">
            <button onClick={toggleLanguage}>
              {language === 'en' ? 'Français' : 'English'}
            </button>
          </div>
          <img src="/assets/llens_bg.png" alt="SolidRights Logo" />
        </div>
      </div>
    </header>
  );
};

export default Header;
