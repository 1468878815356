
import React from 'react';
import Header from './components/Header';
import About from './components/About';
import Features from './components/Features';
import ContactForm from './components/ContactForm';
import {LanguageProvider} from './contexts/LanguageContext';
import Footer from './components/Footer';
import SupportedBy from './components/SupportedBy';
import './styles/variables.css';
import './styles/App.css';
import Contacts from "./components/Contacts";

function App() {
  return (
    <LanguageProvider>
      <div className="App">
        <Header />
        <main>
          <About />
          <Features />
          <Contacts />
          <SupportedBy />
        </main>
        <ContactForm />
        <Footer />
      </div>
    </LanguageProvider>
  );
}


export default App;
