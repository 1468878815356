import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const Contacts: React.FC = () => {
  const { translate } = useLanguage();

  const openContactForm = () => {
    const overlay = document.getElementById('contact-form-overlay');
    if (overlay) {
      overlay.style.display = 'flex';
    }
  };

  return (
    <section id="contacts" className="section bg-light">
      <div className="container contacts"> {translate('contacts_header')}
          <div className="contact-link">
            <button onClick={openContactForm}>{translate('contacts_btn')}</button>
          </div>
      </div>
    </section>
  );
};

export default Contacts;
