import React, { useEffect, useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const ContactForm = () => {
  const { translate } = useLanguage();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{success?: boolean; message?: string} | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    // Use environment variable for API URL if available, otherwise use default
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    
    try {
      const response = await fetch(`${apiUrl}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (data.success) {
        setSubmitStatus({ success: true, message: translate('contact_success') });
        setFormData({ name: '', email: '', message: '' });
      } else {
        setSubmitStatus({ success: false, message: translate('contact_error') });
      }
    } catch (error) {
      setSubmitStatus({ success: false, message: translate('contact_error') });
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    // Add event listener to close the overlay when the close button is clicked
    const closeButton = document.getElementById('close-overlay');
    if (closeButton) {
      closeButton.addEventListener('click', () => {
        const overlay = document.getElementById('contact-form-overlay');
        if (overlay) {
          overlay.style.display = 'none';
        }
      });
    }

    // Cleanup event listener on component unmount
    return () => {
      const closeButton = document.getElementById('close-overlay');
      if (closeButton) {
        closeButton.removeEventListener('click', () => {});
      }
    };
  }, []);

  return (
    <div id="contact-form-overlay" className="overlay">
      <div className="overlay-content bg-light">
        <span id="close-overlay" className="close-btn">&times;</span>
        <h2>{translate('contact_title')}</h2>
        {submitStatus?.success ? (
          <div className="success-message">
            <p>{submitStatus.message}</p>
            <button 
              onClick={() => {
                const overlay = document.getElementById('contact-form-overlay');
                if (overlay) overlay.style.display = 'none';
              }} 
              className="btn"
            >
              {translate('close')}
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">{translate('contact_name')}</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              value={formData.name}
              onChange={handleChange}
              required 
            />
            <label htmlFor="email">{translate('contact_email')}</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              value={formData.email}
              onChange={handleChange}
              required 
            />
            <label htmlFor="message">{translate('contact_message')}</label>
            <textarea 
              id="message" 
              name="message" 
              rows={5} 
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>

            {submitStatus?.success === false && (
              <div className="error-message">
                <p>{submitStatus.message}</p>
              </div>
            )}

            <button 
              type="submit" 
              className="btn" 
              disabled={isSubmitting}
            >
              {isSubmitting ? translate('contact_sending') : translate('contact_btn')}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;